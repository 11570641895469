.popupWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;
}

.popupWrapper.active{
    z-index: 999;
    opacity: 1;
}

.popupContainer{
    width: 1000px;
    margin: auto;
    background: var(--white);
    border: 1px solid #EEEEEE;
    border-radius: 15px;
    overflow: hidden;
}

.popupHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #EEEEEE;
}

.popupBody{
    padding: 20px;
}

.TwoSections{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.flexend{
    justify-content: flex-end;
}

.popupFooter{
    padding: 20px;
    padding-top: 0;
}

.popupFooter button{
    font-size: 14px;
    padding: 10px 12px;
}

.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.popupAddcsv .buttonWrapper{
    justify-content: space-between;
}
.popupAddcsv .buttonWrapper > a{
    padding: 10px 12px;
    border-radius: 6px;
    color: var(--green);
     background: rgba(0, 135, 90, 0.1);
}

.close{
    display: flex;
    align-items: center;
    padding: 2px;
    border: 1px solid var(--grey2);
    border-radius: 5px;
    cursor: pointer;
}

.cashdetailPopup .filterDrop{
    margin: 0;
    padding: 0;
    width: 100%;
}

.cashdetailPopup .popupBody{
    max-height: 450px;
    overflow: auto;
    padding: 0;
}

.cashdetailPopup .popupBody{
    padding: 20px;
}

.Csboxuplodeimg .boxLabel{
    max-width: calc(50% - 10px);
}

.Csboxuplodeimg .fileChosen{
    width: 75%;
    overflow: hidden;
    display: inline-block;
}

.Csboxuplodeimg .uploadLeft{
    width: 95%;
}

.twoInvoices .fileChosen{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 300px;
}

.twoInvoices .uploadLeft{
    display: flex;
    align-items: center;
    white-space: nowrap;
}