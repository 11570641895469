.uploadBox {
    color: var(--black2);
    background: var(--grey3);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
}

.uploadBox label{
    color: var(--white);
    background: var(--black2);
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    padding: 1px 8px;
    border: 1px solid var(--black2);
    display: inline-block;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
}

.boxLabel{
    width: 100%;
}

.fileChosen{
    margin-left: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: var(--black2);
}

.uploadRight{
    line-height: 0;
}

.label{
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: var(--black);
    margin-bottom: 5px;
}