.formGroups{
    display: block;
    position: relative;
    width: 100%;
}

.formGroups label{
    display: block;
    position: relative;
    font-size: 13px;
    line-height: 120%;
    color: var(--black5);
    margin-bottom: 5px;
}

.impRed{
    color: var(--red);
}

.formGroups input{
    background: var(--grey3);
    min-height: 40px;
    display: block;
    width: 100%;
    padding: 10px;
    min-height: 44px;
    border: 1px solid var(--grey3);
    border-radius: 10px;
}

.formGroups input:focus{
    outline: none;
}

.FormError {
    font-size: 12px;
    color: var(--danger-5);
}

.visible0{
    visibility: hidden;
}

.FormError{
    color: var(--red);    
    text-transform: capitalize;
    margin-top: 2px;
    font-size: 12px;
    display: block;
    position: relative;
}