.addNotesPopupmain{
    max-width: 600px;
    width: 100%;
}

.textareabody{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;
}

.addNotesPopupmain .textarea{
  width: 100%;
  max-width: 80%;
  border: 1px solid var(--black4);;
  outline: none;
  padding: 5px;
  border-radius: 4px;
  font-weight: 400;
    font-size: 13px;
    color: var(--black1);
    margin-bottom: 5px;
}

.innertextarea{
    width: 100%;
    max-width: 80%;
    text-align: left;
}