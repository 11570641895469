.button{
    border: 1px solid transparent;
    background: transparent;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer; 
    line-height: 20px;
    position: relative;
}

.blueBtn{
    border-color: var(--blue);
    background: var(--blue);
    color: var(--white);
}

.blackBtn{
    border-color: var(--black);
    background: var(--black);
    color: var(--white);
}

.whiteBtn{
    border-color: var(--grey2);
    background: var(--white);
    color: var(--black3);
}

.button img{
    max-width: 14px;
}

.iconButton{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 4px;
}

.BlueBorderButton{
    border: 1px solid var(--blue);
    color: var(--blue);
}

.BlueFillButton{
    border: 1px solid var(--blue);
    background: var(--blue);
    color: var(--white);
}

.GreyFillButton{
    border: 1px solid var(--grey2);
    background: var(--grey2);
    color: var(--black);
}

.GreyBorderButton{
    border: 1px solid var(--grey2);
    background: var(--white);
    color: var(--black);
}

.BlackFillButton{
    border: 1px solid var(--black);
    background: var(--black);
    color: var(--white);
}

.RedFillButton{
    border: 1px solid var(--red);
    background: var(--red);
    color: var(--white);
}

.RedBorderButton{
    border: 1px solid var(--red);
    background: var(--white);
    color: var(--red);;
}

.BlackBorderButton{
    border: 1px solid var(--black);
    background: var(--white);
    color: var(--black);
}

.GreenFillButton{
    border: 1px solid var(--green);
    background: var(--green);
    color: var(--white);
}

.invertImg img{
    filter: invert(1);
}

.blueTextButton{
    color: var(--blue);
}

.reverseIcon{
    flex-direction: row-reverse;
}

.loginButton{
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-top: 40px;
    padding: 15px;
}

.iconReversed{
    flex-direction: row-reverse;
}

.bluetextBtn{
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: var(--blue);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    cursor: pointer;
}

.btn-lg{
    width: 100%;
}

.loginButton{
    font-size: 16px;
    line-height: 140%;
}