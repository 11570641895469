.checkbox-label span.checkmarkspan {
  margin-right: 5px;
}
.checkbox .checkbox-label{
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: 'ns-regular';
}

.form-1 {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey1);
  border-radius: 5px;
  padding: 30px;
  background: var(--white);
  max-width: 460px;
  width: 460px;
  min-height: 400px;
}

.form-1-top {
  text-align: center;
}

/* COMMONT FORM-1 END */
.loginCenter .form-group {
  padding: 0;
}

.input-group {
  display: flex;
  flex-direction: column-reverse;
}

.form-label {
  font-family: "Inter Light BETA";
  font-size: 11px;
  color: var(--dark-grey);
}

.input-box {
  border: 1px solid var(--grey);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Inter Light BETA";
  outline: none;
  caret-color: var(--blue);
}

.loginContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.text-1a{
  font-size: 14px;
  color: var(--grey);
}

.heading-1{
  font-size: 26px;
  font-family: 'Inter Bold';
}

.blueButton {
  color: var(--white);
  background: #3397EF;
  border-color: #3397EF;
}