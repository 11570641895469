.labelWrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-left: 30px;
    min-height: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.labelWrap input{
    display: none;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--white);
    border-radius: 50%;
    border: 2px solid var(--blue);
}

.checkmark:after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.labelWrap input:checked ~ .checkmark {
    background-color: transparent;
}

.labelWrap input:checked ~ .checkmark:after {
    opacity: 1;
    transition: 0.4s ease-in-out;
}

.labelWrap .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--blue);
}

.labelWrap input:checked + .radioText{
    color: var(--blue);
}

.radioText{
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}

.radioWrap{
    display: block;
    position: relative;
}