.secondHeader{
    display: block;
    z-index: 100;
    background: var(--white);
    padding: 20px 40px !important;
    border-bottom: 2px solid var(--grey-3);
}


.headerWrap{
    padding: 10px 20px;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey2);
}

.divider{
    display: block;
    position: relative;
    height: 20px;
    width: 1px;
    background: var(--grey2);
}

.text-black3{
    color: var(--black3);
}

.headerRight{
    gap: 12px;
}

.text-14{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}