.MasterExistBody {
    max-width: 450px;
}

.MasterExistBody .ContentBody {
    padding: 20px 30px;
}

.MasterExistBody .footerButton {
    justify-content: flex-end;
}

.MasterExistBody .textMnExit {
    font-size: 13px;
    font-weight: 500;
    color: var(--black);
    padding: 15px 0 30px 0;
    line-height: 150%;
}

.MasterExistBody .popupHeading{
    font-weight: 700;
}