.searchWrap{
    gap: 6px;
    max-width: 120px;
    justify-content: flex-start;
    position: relative;
}

.searcBox{
    border: none;
    max-width: 100px;
    position: relative;
    display: block;
}

.searcBox:focus,
.searcBox:focus-visible,
.searcBox:focus-within{
    outline: none;
    border: none;
}

.searcBox::placeholder{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: var(--grey);
}