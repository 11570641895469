.MasterWrapper {
    max-width: 1280px;
    margin: 0 auto;
}

.masterDetailOuter {
    background: var(--white);
    border-radius: 6px;
    padding: 10px 16px 10px 10px;
}

.masterDetail {
    width: 100%;
}

.text-13{
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: var(--black4);
}

.masterDetail .text-13 b{
    color: var(--black);
    font-weight: 600;
}

.masterHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contactDetails {
    width: 46%;
    justify-content: space-between;
}

.contacttopcard{
    max-width: 100%;
    width: 100%;
}

.contacttopcard2{
    max-width: 100%;
    width: 100%;
}

.contacttopcard2 .box2col{
    width: 100%;
    justify-content: flex-start;
}

.contacttopcard2 .box2col > p{
    max-width: max-content;
}

.contacttopcard p,
.contacttopcard2 p{
    max-width: 50%;
    width: 100%;
}

.text-18{
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: var(--blue);
}

.mastersTab{
    background: var(--white);
    border-radius: 6px;
    padding: 0 10px;
}

.mastersTab .divider{
    margin:0 13px;
}

.Tab{
    cursor: pointer;
    gap: 6px;
    padding: 13px 0;
    transition: 0.4s;
    border-bottom: 2px solid transparent;
}

.Tab .text-13,
.Tab svg path{
    color: var(--black2);
    transition: 0.4s ease-in-out;
}

.Tab:hover{
    border-bottom: 2px solid var(--blue);
}

.Tab:hover .text-13,
.Tab:hover svg path{
    color: var(--blue);
    stroke: var(--blue);
    transition: 0.4s ease-in-out;
}

.Tab.active{
    border-bottom: 2px solid var(--blue);
}

.Tab.active .text-13,
.Tab.active svg path{
    color: var(--blue);
    stroke: var(--blue);
    transition: 0.4s ease-in-out;
}

.masterTabDetail{
    background: var(--white);
    border-radius: 6px;
    padding: 16px;
    display: none;
    cursor: pointer;
}    

.masterTabDetail.active{
    display: block;
}

.accordianWrapper .accoedianQuestion img{
    transition: 0.4s ease-in-out;
    transform: rotate(180deg);
}

.accordianWrapper.active .accoedianQuestion img{
    transition: 0.4s ease-in-out;
    transform: rotate(0deg);
}

.accordianWrapper{
    background: var(--white);
    border: 1px solid #DFDFDF;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    max-height: 50px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
}

.accordianWrapper.active{
    max-height: 100vh;
    transition: 0.4s ease-in-out;
}

.accoedianQuestion.flexbox.blackHead {
    background: var(--black);
    justify-content: space-between;
    padding: 15px;
    width: 100%;
}

.accordianAnswer{
    padding: 15px;
}

.accordianAnswer .text-13 b{
    color: var(--black2);
    font:500;
}

.accordianAnswer .box2col{
    justify-content: space-between;
}

.accordianHead{
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    padding:5px 0 10px;
    color: var(--black);
    border-bottom: 1px solid #DFDFDF;
}

.accordianAnswer .g-Tag{
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 13px;
    line-height: 120%;
    text-transform: capitalize;
    padding:8px 10px;
}

.masterEngaement.flexbox{
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #DFDFDF;
}

.masterEngaement .text-18{
    color: var(--black2);
    font-weight: 500;
    line-height: 120%;
}

.text-16{
    font-size: 16px;
    line-height: 120%;
    color: var(--black);
}

.accordianInner{
    padding: 15px 20px;
}

.accordianInner .accordianAnswer {
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    width: 100%;
    position: relative;
}

.floatingEdit{
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.floatingEdit svg{
    max-width: 20px;
}

.accoedianQuestion.flexbox {
    background: var(--white);
    width: 85%;
    flex-wrap: wrap;
    row-gap: 10px;
    max-height: 49px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
}

.accordianQuestionWrapper.flexbox::after{
    position: absolute;
    content: '';
    top: 50px;
    width: 1px;
    height: 97%;
    left: 25px;
    border-right: 1px dashed #737373;
}

.accordianInner:last-child .accordianQuestionWrapper.flexbox::after{
    display: none;
}

.accordianQuestionWrapper.active .accoedianQuestion.flexbox{
    max-height: 100vh;
    transition: 0.4s ease-in-out;
    position: relative;
}

.accoedianQuestion.flexbox.b-Tab {
    background: rgba(82, 67, 170, 0.05);
    border-left: 4px solid #5243AA;
    border-radius: 3px;
    width: 85%;
    padding: 15px;
}

.accordianQuestionWrapper.flexbox {
    justify-content: space-between;
    align-items: baseline;
    position: relative;
}

.deadLine{
    position: relative;
}


.text-12 {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--black4);
}

.text-12 b{
    color: var(--black);
    font-weight: 500;
}

.boxWrapData{
    flex-wrap: wrap;
    width: 100%;
    background: rgba(0, 135, 90, 0.3);
    border-left: 4px solid #00875A;
    border-radius: 3px;
    padding: 15px;
}

.boxWrapData.b-Tab{
    background: rgba(82, 67, 170, 0.05);
    border-left: 4px solid var(--blue);
}

.square134{
    width: 134px;
    height: 134px;
    object-fit: cover;
}

.arrowBox{
    transform: rotate(180deg);
    transition: 0.4s ease-in;
}

.accordianQuestionWrapper.active .arrowBox{
    transform: rotate(0deg);
    transition: 0.4s ease-in;
}

.twoPieceBox{
    flex-wrap: wrap;
}

.twoPieceBox .box2col{
    min-width: max-content;
}

.wrk{
    padding-left: 15px;
    font-size: 15px;
}
.wrk > p ,.wrk2{
    font-size: 13px;
    color: var(--black4);
    font-weight: 600;
}

.mainboxreviewtext{
    padding: 20px;
}
.ratingboxshow{
    width: 100%;
    padding: 10px;
    max-width: 100%;
    border:  1px solid #DFDFDF;
    border-radius: 6px;
}
.ratingboxshow .reviewtext{
    color: var(--black);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.mainboxreviewtext .datefield{
    border-bottom:  1px solid #DFDFDF;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 8px;
}
.mainboxreviewtext .datefield span{
    color: var(--black4);
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: var(--green);
    background: rgba(0, 135, 90, 0.1);
    border-radius: 3px;
    width: max-content;
    padding: 4px 8px;
}