
.flexbox.popUpFlex{
    align-items: flex-start;
    height: 100%;
}

.popUpLeft {
    width: 30%;
    background: #FAFAFA;
    padding: 20px;
    height: 100%;
}

.docIcon{
    margin-bottom: 60px;
    position: relative;
}

.docIcon.active svg path{
    stroke: var(--white);
}

.docIcon.active svg rect{
    fill: #00875A;
    fill-opacity: 1;
}

.docIcon.active.current svg rect{
    fill: #5243AA;
    fill-opacity: 1;
}

.docIcon:last-child{
    margin-bottom: 0;
}

.docIcon::after {
    position: absolute;
    content: '';
    bottom: -55px;
    left: 20px;
    border: 1px dashed var(--grey);
    height: 100%;
}

.docIcon:last-child::after{
    display: none;
}

.popUpRight {
    width: 70%;
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.formRight{
    display: none;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.formRight.active{
    display: block;
}

.masterDetailPopup .popupFooter{
    padding: 0;
    width: 100%;
}


.masterDetailPopup .popupBody{
    max-height: 550px;
    height: 100vh;
}

.userImage{
    display: block;
    position: relative;
}

.userImageWrap{
    display: block;
    width: 100px;
    height: 100px;
    /* background: url('/public/assets/img/userBG.png'); */
    border-radius: 10px;
    position: relative;
}

.userImageWrap .editicon{
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    max-width: 22px;
}

.textWarning{
    color: var(--red);
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    margin-top: 8px;
    margin-bottom: 10px;
}

.userLabel{
    display: block;
    position: relative;
    z-index: 99;
    height: 100%;
    cursor: pointer;
}

.userProfile{
    max-width: 100%;
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    overflow: hidden;
}

.userLabel input{
    display: none;
}

.lableName{
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 8px;
    display: block;
}

.adjustMentMulti{

}

.adjustMentMulti .customDropdownWrap {
    width: 100%;
    padding: 0;
    margin-top: 0;
}

.pointer{
    cursor: pointer;
}

.buttonWrapForm{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.buttonWrapForm .bluetextBtn{
    max-width: max-content;
}

.TagInputbar .rti--container{
    background: var(--grey3);
    display: block;
    width: 100%;
    min-height: 44px;
    border: 1px solid var(--grey3);
    border-radius: 10px;
    width: 100%;
    padding: 0 10px;
}

.TagInputbar .rti--container input{
    min-height: 40px;
    padding: 10px;
    background: var(--grey3);
    max-width: 150px;
    width: 100%;
}

.TagInputbar .rti--container:focus-within{
    border-color: transparent;
    box-shadow: none;
}

.TagInputbar .rti--container{
    font-size: 12px;
    line-height: 22px;
    color: #404040;
    font-weight: 400;
    display: flex;
    gap: 5px;
}

.TagInputbar .rti--tag{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 1px 7px;
}

.TagInputbar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.labletabinput{
    display: block;
    position: relative;
    font-size: 13px;
    line-height: 120%;
    color: var(--black5);
    margin-bottom: 5px;
}

/* 




*/