*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.mainWrapper.fullHeight{
  max-height: calc(100vh - 63px);
}

.mainContainer{
  height: 100vh;
}

.mainWrapper{
  background: var(--grey2) ;
  padding: 20px;
  max-height: calc(100vh - 109px);
  overflow: auto;
  height: 100vh;
}

.mainWrapper.singleHeader{
  height: calc(100vh - 55px);
  max-height: 100vh;
}