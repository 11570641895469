.filterWrapper {
    justify-content: space-between;
}

.text-20 {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: var(--black);
}

.text-white {
    color: var(--white) !important;
}

.width33 {
    width: 33% !important;
}

.width50 {
    width: 50% !important;
}

.width66 {
    width: 66% !important;
}

.tableWrapper {
    padding: 12px 12px;
    background: var(--white);
    margin-top: 12px;
    height: calc(100vh - 141px);
    overflow: auto;
    border-radius: 8px;
}

.blackTable {
    box-sizing: border-box;
    width: 100%;
    border-spacing: 0;
}

.blackHeader {
    background: var(--black);
    padding: 10px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: var(--white);
    text-transform: uppercase;
    text-align: center;
    /* width: 20%; */
}

.MuAllMasters .blackHeader {
    width: 20%;
}

.blackHeader:first-child {
    border-radius: 6px 0 0px 6px;
}

.blackHeader:last-child {
    border-radius: 0px 6px 6px 0px;
}

.tableinner {
    padding: 10px;
    border-bottom: 1px solid var(--grey2);
}

.text-13 {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--black2);
}

.classDivider {
    border: 1px solid #DFDFDF;
}

.text-green {
    color: var(--green) !important;
}

.form2col {
    justify-content: flex-start;
    gap: 20px;
}

.profileImg {
    width: 52px;
    height: 52px;
    max-width: 52px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.paginationAddon {
    height: calc(100vh - 186px);
}

.paginationAddon table .stickyTable {
    position: sticky;
    top: 0;
    z-index: 0;
}

.paginationWrapper {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pagination span {
    min-height: 32px;
    display: block;
    position: relative;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: var(--black4);
    border-radius: 10px;
    cursor: pointer;
}

.pagination span:hover {
    background: var(--white);
}

.pagination span.active {
    background: var(--white);
    color: var(--darkblack);
}

.filterWrapper .doubleBtn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filterWrapper .downloaddata img {
    filter: brightness(0.5);
}

.ViewMoreButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
}


.tablemaindev .tableWrapper {
    padding: 0;
}

.tablemaindev .blackHeader {
    border-radius: unset;
}